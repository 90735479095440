import React from 'react';

import classes from './contactUs.module.css';

const ContactUs = (props) => {
    let AreWeOpen = "";
    const date = new Date();
    let day = date.getDay();
    let hour = date.getHours();
    let minutes = date.getMinutes();
    let shours = 18 - hour;
    let sshours = 17 - hour;
    let sminutes = 60 - minutes;
    let today = "";
    if (sminutes !== 0) {
        shours = 17 - hour;
        sshours = 16 - hour;

    }


    const getDay = () => {
        switch (day) {
            case 0:
                return today = "Monday";
            case 1:
                return today = "Tuesday";
            case 2:
                return today = "Wednesday";
            case 3:
                return today = "Thursday";
            case 4:
                return today = "Friday";
            case 5:
                return today = "Saterday";
            case 6:
                return today = "Monday";
        }
    }

    getDay();

    const getAreWeOpen = () => {
        switch (day) {
            case 0:
                return AreWeOpen = <h1>We are open again on {today} at 8:30am</h1>;
            case 6:
                if (hour < 9 || hour > 15)
                    return AreWeOpen = <h1>We are open again on {today} at 8:30am</h1>;
                else
                    if (sshours === 0)
                        return AreWeOpen = <h1>We are open for another {sminutes} minutes </h1>;
                    else return AreWeOpen = <h1>We are open for another {sshours} and {sminutes} minutes </h1>;
            default:
                if (hour < 9 || hour > 18)
                    return AreWeOpen = <h1>We are open again on {today} at 9:00am</h1>;
                else
                    if (shours === 0)
                        return AreWeOpen = <h1>We are open for another {sminutes} minutes </h1>
                    else return AreWeOpen = <h1>We are open for another {shours} hours and {sminutes} minutes </h1>;
        }
    }
    getAreWeOpen();

    return (
        <div className={classes.containerContactUs} >
            <br />
            <div className={classes.hoursb}>
                <div className={classes.hoursbox}>
                    <h2 style={{ color: "#007aff" }}> Contact Us</h2>
                    <div className={classes.hours}>
                        <div>
                            <ul>
                                <li>Days</li>
                                <li>Monday</li>
                                <li>Tuesday</li>
                                <li>Wednesday</li>
                                <li>Thursday</li>
                                <li>Friday</li>
                                <li>Saturday</li>
                                <li>Sunday</li>
                            </ul>
                        </div>
                        <div>
                            <ul>
                                <li>Hours</li>
                                <li>8:30am to 7:00pm</li>
                                <li>8:30am to 7:00pm</li>
                                <li>8:30am to 7:00pm</li>
                                <li>8:30am to 7:00pm</li>
                                <li>8:30am to 7:00pm</li>
                                <li>9:00am to 5:30pm</li>
                                <li>Closed</li>
                            </ul>
                        </div >
                        <div>
                            <ul>
                                <li>Location</li>
                                <li>2965 Islington Ave</li>
                                <li>North York, Ontario</li>
                                <li>M9L 2K8</li>
                                <li>Italian Gardens Plaza</li>
                                <li>After Finch and Close to Steeles</li>
                                <li>Where the CIBC BANK is located as well</li>
                                <li style={{ color: "red" }}>Tel: 416.749.9069</li>
                            </ul>
                        </div>
                    </div>
                    <hr />
                    <div className={classes.isOpen}>
                        {AreWeOpen}
                    </div>
                </div>
            </div>
            <br />
        </div >
    )
}

export default ContactUs;

